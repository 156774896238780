import React from 'react';
import { styled, theme } from '../../Theme';
import { media, pseudo } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import { StyledProps } from '@glitz/react';

type PropType = Partial<StyledProps> & {
  layout?: string;
  children: JSX.Element | string;
  extraMargin?: boolean;
  childStyle?: StyleOrStyleArray;
};

const GridItem: React.FC<PropType> = ({
  layout = '1/1',
  children,
  extraMargin = false,
  childStyle = {},
  compose,
}) => {
  const style = {
    ...childStyle,
    ...(extraMargin && { paddingBottom: theme.spacing(8) }),
  };

  return (
    <GridItemContainer data-gridsize={layout} css={compose?.()}>
      <ChildContainer css={style}>{children}</ChildContainer>
    </GridItemContainer>
  );
};

const GridItemContainer = styled.div({
  width: '100%',
  height: '100%',
  margin: { x: 'auto' },
  gridColumnStart: 'auto',
  gridColumnEnd: 'span 12',
  maxWidth: theme.blockMaxWidthMobile,
  ...media(theme.mediaQuery.mediaMinMedium, {
    maxWidth: 'unset',
    ...pseudo([':nth-child(n)[data-gridsize="1/2"]'], {
      gridColumnEnd: 'span 6',
    }),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    ...pseudo([':nth-child(n)[data-gridsize="1/3"]'], {
      gridColumnEnd: 'span 4',
    }),
    ...pseudo([':nth-child(n)[data-gridsize="1/4"]'], {
      gridColumnEnd: 'span 3',
    }),
    margin: { x: theme.none },
  }),
  ...pseudo([':nth-child(n)[data-gridsize="FullWidth"]'], {
    maxWidth: 'unset',
    ...media(theme.mediaQuery.mediaMinGreat, {
      maxWidth: 'unset',
      marginLeft: `calc((${theme.contentMaxWidth}px - 100vw) / 2)`,
      marginRight: `calc((${theme.contentMaxWidth}px - 100vw) / 2)`,
      width: '100vw',
    }),
    padding: { x: theme.none },
  }),
});

const ChildContainer = styled.div({
  height: 'auto',
});

export default GridItem;
