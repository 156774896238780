import React from 'react';
import { styled, theme } from '../../Theme';
import { media, pseudo } from '@glitz/core';
import { StyledProps } from '@glitz/react';
import { Style, StyleOrStyleArray } from '@glitz/type';

type HeadingType = StyledProps & {
  title: string;
  size?: StyleOrStyleArray;
  subUnderline?: boolean;
};

function Heading({
  title,
  size = DefaultFontSize,
  subUnderline = true,
  compose,
  ...rest
}: HeadingType) {
  let titleStyles: StyleOrStyleArray = [
    size as Style,
    subUnderline ? (SubUnderlineStyles as Style) : {},
  ];
  return (
    <>
      {title && (
        <TitleContainer css={compose()}>
          <Title css={titleStyles} {...rest}>
            {title}
          </Title>
        </TitleContainer>
      )}
    </>
  );
}

export default Heading;

const TitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(4),
});

const Title = styled.h2({
  margin: {
    x: 'auto',
    bottom: theme.spacing(6),
  },
});

const DefaultFontSize: StyleOrStyleArray = {
  font: { size: theme.eta, weight: theme.fontWeight.lighter },
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: theme.iota },
  }),
};

const SubUnderlineStyles: StyleOrStyleArray = {
  ...pseudo(':before', {
    position: 'relative',
    display: 'flex',
    left: theme.none,
    top: '1.4em',
    height: theme.tiny,
    width: theme.spacing(16),
    content: '""',
    border: {
      top: {
        style: 'solid',
        width: theme.tiny,
        color: theme.primary,
      },
    },
  }),
};
