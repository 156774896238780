import React from 'react';
import { styled, theme } from '../../Theme';
import ImageBlockModel from '../../Blocks/ImageBlock/Models/ImageBlockModel.interface';
import { applyEditModeAttr } from '../../Shared/Common/Helpers';
import Heading from '../../Shared/Heading/Heading';
import { StyleOrStyleArray } from '@glitz/type';
import GridItem from '../../Shared/Grid/GridItem';
import { media } from '@glitz/core';
import { useKexNavigate } from '../../Shared/KexRouter/KexRouter';
import { SmallButton } from '../../Shared/Button/Button';
import useMedia from '../../Shared/Hooks/useMedia';

type PropType = {
  content: ImageBlockModel;
};

function ImageBlock({
  content: {
    heading,
    image,
    mobileImage,
    link,
    inEditMode,
    text,
    layout,
    extraMargin,
  },
}: PropType) {
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);
  const kexNavigate = useKexNavigate();

  const containerBackgroundStyle = {
    backgroundImage: `url(${mobileImage?.href || image?.href})`,
    ...(!isMobile && {
      backgroundImage: `url(${image?.href || ''})`,
    }),
  };
  return (
    <GridItem layout={layout} extraMargin={extraMargin}>
      <Container css={containerBackgroundStyle}>
        <ImageTextDiv>
          {heading && (
            <StyledHeading
              title={heading}
              subUnderline={false}
              size={HeadingSize}
              {...applyEditModeAttr(inEditMode && 'Heading')}
            />
          )}
          <TextDiv {...applyEditModeAttr(inEditMode && 'Text')}>{text}</TextDiv>
          {link && link.href ? (
            <LinkDiv {...applyEditModeAttr(inEditMode && 'Link')}>
              <LinkButton
                onClick={() => {
                  link.href && kexNavigate(link.href);
                }}
                key={link.id}
                css={PrimaryButton}
              >
                {link.text === undefined ? '' : link.text}
              </LinkButton>
            </LinkDiv>
          ) : (
            <></>
          )}
        </ImageTextDiv>
      </Container>
    </GridItem>
  );
}

const Container = styled.div({
  position: 'relative',
  textAlign: 'center',
  color: theme.white,
  margin: { xy: theme.none },
  height: theme.imageBlockHeight,
  backgroundSize: 'cover',
  backgroundPosition: '50% 50%',
});

const StyledHeading = styled(Heading, {
  font: { weight: theme.fontWeight.bold },
  marginBottom: theme.none,
  color: theme.white,
});
const ImageTextDiv = styled.div({
  position: 'relative',
  font: { size: theme.delta },
  lineHeight: theme.lineHeight.snug,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  top: theme.spacing(36),
  height: '100%',

  padding: { x: theme.spacing(4) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    textAlign: 'center',
  }),
});

const LinkDiv = styled.div({
  font: { size: theme.gamma },
});

const TextDiv = styled.div({
  font: { size: theme.gamma },
});

const HeadingSize: StyleOrStyleArray = {
  font: { size: theme.nu },
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: theme.iota },
    margin: {
      x: theme.none,
      bottom: theme.spacing(4),
    },
  }),
};

const LinkButton = styled(SmallButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.blueDark,
  height: 'auto',
  padding: { x: theme.spacing(8), y: theme.spacing(4) },
  border: { xy: { width: theme.none } },
  margin: { y: theme.spacing(6) },
  color: theme.white,
  width: '100%',
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: 'auto',
    margin: { y: theme.spacing(8), right: theme.spacing(4) },
  }),
});

const PrimaryButton: StyleOrStyleArray = {
  ':hover': {
    backgroundColor: theme.primary,
  },
};

export default ImageBlock;
